<template>
  <div class="app-context expanded">
    <!-- <app-header :headerOption="headerOption" ref="appheader"></app-header> -->
    <join-progress :progressInfo="progressInfo"></join-progress>
    <div class="context">
      <directive :directiveInfo="directiveInfo"></directive>
      <div class="join">
        <div class="store-btn-wrap">
          <button type="button" class="active" @click="next()">
            메인으로
          </button>
        </div>
      </div>
    </div>
    <!-- <app-footer></app-footer> -->
  </div>
</template>

<script>
// import AppHeader from "@/components/AppHeader";
import JoinProgress from "@/components/join/ProgressSub";
import Directive from "@/components/join/Directive";
// import AppFooter from "@/components/AppFooter";
export default {
  components: {
    // AppHeader,
    JoinProgress,
    Directive,
    // AppFooter
  },
  data() {
    return {
      progressInfo: {
        activeNum: 2,
        progressName1: "검색정보 입력완료",
        progressName2: "소개정보 입력",
        progressName3: "소개정보 입력완료"
      },
      headerOption: {
        navOrder: 1
      },
      directiveInfo: {
        title: "입력완료",
        isTitleNewLine: false,
        content:
          "부가정보가 정상적으로 입력되었습니다.\n 마이페이지에서 부가정보를 관리할 수 있습니다. ",
        isContentNewLine: true,
        warn: null,
        isWarnNewLine: false
      }
    };
  },
  created() {},
  mounted() {},
  computed: {
  },
  methods: {
    next() {
      this.$router.push("/").catch(()=>{});
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/join";
.join {
  .store-btn-wrap {
    width: 390px;
    button {
      font-size: 23px;
      line-height: 34px;
      &.addInfo {
        background: #474747;
      }
    }
  }
}
</style>
